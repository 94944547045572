import image1 from '../Assets/html-image.png'
import image2 from '../Assets/css-image.png'
import image3 from '../Assets/js-image.png'
import image4 from '../Assets/bootstrap-image.png'
import image5 from '../Assets/react-image.png'
import image6 from '../Assets/git-image.png'

export const skillData = [
    {
        img: image1,
        title: 'HTML'
    },
    {
        img: image2,
        title: 'CSS'
    },
    {
        img: image3,
        title: 'JS'
    },
    {
        img: image4,
        title: 'BootStrap'
    },
    {
        img: image5,
        title: 'React'
    },
    {
        img: image6,
        title: 'Git'
    }
]