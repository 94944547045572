import React from 'react'
import './skills.css'
import {skillData} from '../../Data/SkillData'

const Skills = () => {
    return (
        <div className='skills'>
            <div className="container">
                <div className="title">
                    <span>Skills</span>
                </div>
                <div className="tech">
                    {
                        skillData.length > 0 ? 
                        skillData.map((item, index) => 
                            <div className="box" key={index}>
                                <img src={item.img} alt="" />
                                <span>{item.title}</span>
                            </div>
                        ) : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Skills
