import React, { useRef } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser'

const Contact = () => {
    const formRef = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        if(
            document.querySelector("form .name").value !== "" 
            && document.querySelector("form .email").value !== "" 
            && document.querySelector("form .message").value !== ""){
            emailjs.sendForm('service_byhwo1n', 'template_0g12q3h', formRef.current, 'TSmL_Ezqv323M6J3q')
                .then((result) => {
                    console.log(result.text);
                    document.querySelector("form .name").value = "" ;
                    document.querySelector("form .email").value = "";
                    document.querySelector("form .message").value = "";
                    document.querySelector(".wrong-data-message").style.display = "none";
                    document.querySelector(".correct-data-message").style.display = "block";
                }, (error) => {
                    console.log(error.text);
                });

        }else{
            document.querySelector(".wrong-data-message").style.display = "block";
            document.querySelector(".correct-data-message").style.display = "none";
        }
    };

    return (
        <div className='contact'>
            <div className="container">
                <div className="title">
                    <span>Contact</span>
                </div>
                <form ref={formRef} onSubmit={sendEmail}>
                    <input className='name' type="text" name="user_name" id="name" placeholder='Your Name' />
                    <input className='email' type="email" name="user_email" id="email" placeholder='Your Email' />
                    <textarea className='message' name="message" id="message"  rows="5" placeholder='Your message'></textarea>
                    <div className="wrong-data-message">
                        <i className="fa-solid fa-circle-exclamation"></i>
                        All fields are required
                    </div>
                    <div className="correct-data-message">
                        <i className="fa-solid fa-check"></i>
                        Your message are sent
                    </div>
                    <button type="submit">Send Message</button>
                </form>
            </div>
        </div>
    )
}

export default Contact
