import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
    return (
        <div className='footer'>
            <div className="container">
            <nav>
                <ul className="footer-links">
                    <li>
                        <Link to='https://www.instagram.com/shiref_nohammed/'>
                            <i className="fa-brands fa-instagram"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to='https://www.linkedin.com/in/shiref-mohammed-57342624b/'>
                            <i className="fa-brands fa-linkedin"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to='https://github.com/ShirefMohammed'>
                            <i className="fa-brands fa-github"></i>
                        </Link>
                    </li>
                </ul>
            </nav>

            <div className='copy-right'>
                    &copy; 2023 shiref mohammed.All rights reserved.
            </div>
            </div>
        </div>
    )
}

export default Footer
