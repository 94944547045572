import React from 'react'
import './NoPage.css'

const NoPage = () => {
    return (
        <div className='no-page'>
            Error 404 Go to back step
        </div>
    )
}

export default NoPage
