import React from 'react'
import { Link } from 'react-router-dom'
import './Home.css'

const Home = () => {
    const mailTo = (e) => {
        e.preventDefault();
        window.location.href = "mailto:shirefmohammed@std.mans.edu.eg";
    }
    
    return (
        <div className='home'>
            <div className="container">
                <div className="name">
                    <span>Shiref mohammed</span>
                </div>
                
                <div className="email">
                    <Link to='#' onClick={mailTo}>shirefmohammed@std.mans.edu.eg</Link>
                </div>

                <div className="about">
                    <p>
                        Hello, my name is Shiref Mohammed, and I am a frontend developer. I am passionate about
                        creating beautiful and functional user interfaces that enhance the user experience. I have 
                        experience in HTML, CSS, JavaScript, and frontend frameworks like BootStrap and React JS. 
                        I am always eager to learn new technologies and techniques.
                    </p>
                    <p>
                        In my free time, I am enjoy reading about the latest trends in frontend development and experimenting 
                        with new design ideas. I am excited to bring my skills and enthusiasm to any project I work on, and 
                        I look look forward to collaborating with others to create greet products.
                    </p>
                </div>

                <div className="current-building">
                    <div className='title'>
                        Current Building
                    </div>
                    <ul className="list">
                        <li>
                            <Link to='https://shirefmohammed.github.io/fitness-gym/'>Fitness Gym</Link> 
                            - A web page to promote the commercial work of the sports club through marketing the offers presented, 
                            presenting customer reviews, and facilitating communication with customer service
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Home
