import './App.css';
import './FontAwesome/all.min.css'
import './FontAwesome/normalize.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Component/Header/Header'
import Home from './Component/Home/Home'
import Projects from './Component/Projects/Projects'
import Skills from './Component/Skills/Skills'
import Contact from './Component/Contact/Contact'
import NoPage from './Component/NoPage/NoPage'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Header />}>
          <Route index element={<Home />} />
          <Route path="projects" element={<Projects />} />
          <Route path="skills" element={<Skills />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
