import fitness from '../Assets/fitness-gym.png'
import restaurant from '../Assets/resturant.png'
import seven from '../Assets/seven-theme.png'
import bootstrap1 from '../Assets/bootstrap-1.png'
import bootstrap2 from '../Assets/bootstrap-2.png'
import portfolio from '../Assets/portfolio-templete.png'
import parallax1 from '../Assets/parallax-1.png'
import parallax2 from '../Assets/parallax-2.png'
import parallax3 from '../Assets/parallax-3.png'

export const ProjectsData = [
    {
        img: fitness,
        title: 'Fitness Gym',
        description: 'A web page to promote the commercial work of the sports club made using React JS',
        link: 'https://shirefmohammed.github.io/fitness-gym/'
    },
    {
        img: restaurant,
        title: 'Restaurant App',
        description: 'A web page to display the services provided by the restaurant made using HTML CSS JS',
        link: 'https://shirefmohammed.github.io/restaurant-web-page/'
    },
    {
        img: seven,
        title: 'Seven Theme',
        description: 'Web page to display the designer is work made using HTML CSS JS',
        link: 'https://shirefmohammed.github.io/Seven-theme/'
    },
    {
        img: bootstrap1,
        title: 'Photographers',
        description: 'Web page to display the Photographers is work made using Bootstarp 5',
        link: 'https://shirefmohammed.github.io/bootstrap-templete-1/'
    },
    {
        img: bootstrap2,
        title: 'Kasper',
        description: 'Web App to show services of software company made using Bootstarp 5',
        link: 'https://shirefmohammed.github.io/bootstrap-design-2/'
    },
    {
        img: portfolio,
        title: 'Portfolio Template',
        description: 'Portfolio page help designer and developer to display their work made using HTML CSS JS',
        link: 'https://shirefmohammed.github.io/portfolio-templete/'
    },
    {
        img: parallax1,
        title: 'Parallax Page',
        description: 'Web parallax page to make some effects on the page made using HTML CSS JS',
        link: 'https://shirefmohammed.github.io/parallax-website-2/'
    },
    {
        img: parallax2,
        title: 'Parallax Page',
        description: 'Web parallax page to make some effects on the page made using HTML CSS JS',
        link: 'https://shirefmohammed.github.io/parallax-website-1/'
    },
    {
        img: parallax3,
        title: 'Parallax Page',
        description: 'Web parallax page to make some effects on the page made using HTML CSS JS',
        link: 'https://shirefmohammed.github.io/parallax-website-3/'
    }
]