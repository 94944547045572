import React from 'react'
import { Link } from 'react-router-dom'
import './Projects.css'
import {ProjectsData} from '../../Data/ProjectsData'

const Projects = () => {
    return (
        <div className='projects'>
            <div className="container">
                <div className="title">
                        <span>Projects</span>
                </div>
                <div className="content">
                    {
                        ProjectsData.length > 0 ?
                        ProjectsData.map((item, index) => 
                            <div className="item" key={index}>
                                <img src={item.img} alt="" />

                                <div className="item-title">
                                    <span>{item.title}</span>
                                </div>

                                <div className="item-desc">
                                    <span>{item.description}</span>
                                </div>

                                <div className="view">
                                    <Link to={item.link}>View</Link>
                                </div>
                            </div>
                        ) : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Projects
