import React from 'react'
import { Outlet, Link } from "react-router-dom";
import './Header.css'
import Footer from '../Footer/Footer';

const Header = () => {
    const moonEvent = () => {
        const moon = document.querySelector(".header .mode .moon");
        const sun = document.querySelector(".header .mode .sun");
        moon.classList.add("d-none");
        sun.classList.remove("d-none");
        document.documentElement.style.setProperty('--color-white', '#2C3333');
        document.documentElement.style.setProperty('--color-black', 'white');
    }

    const sunEvent = () => {
        const moon = document.querySelector(".header .mode .moon");
        const sun = document.querySelector(".header .mode .sun");
        moon.classList.remove("d-none");
        sun.classList.add("d-none");
        document.documentElement.style.setProperty('--color-white', 'white');
        document.documentElement.style.setProperty('--color-black', 'black');
    }

    const barsEvent = () => {
        const headerLinks = document.querySelector(".header .header-links");
        headerLinks.classList.toggle("scale-sm-0");
    }

    return (
        <>
            <div className="header">
                <div className="container">
                    <div className="content">
                        <div className="logo">
                            <Link to='/'>Shiref</Link>
                        </div>


                        <ul className='header-links scale-sm-0'>
                            <li>
                                <Link to="/projects">Projects</Link>
                            </li>
                            <li>
                                <Link to="/skills">Skills</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                        </ul>


                        <div className="mode">
                            <div className="moon" onClick={moonEvent}>
                                <i className="fa-solid fa-moon"></i>
                            </div>
                            <div className="sun d-none" onClick={sunEvent}>
                                <i className="fa-solid fa-sun"></i>
                            </div>
                            <div className="bars" onClick={barsEvent}>
                                <i className="fa-solid fa-bars"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Outlet />
            <Footer />
        </>
    )
}

export default Header
